import { render, staticRenderFns } from "./BookingsNew.vue?vue&type=template&id=05cbb39c&scoped=true"
import script from "./BookingsNew.vue?vue&type=script&lang=js"
export * from "./BookingsNew.vue?vue&type=script&lang=js"
import style0 from "./BookingsNew.vue?vue&type=style&index=0&id=05cbb39c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05cbb39c",
  null
  
)

export default component.exports