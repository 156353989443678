<template>
  <div style="height: 100%">
    <v-toolbar flat dense>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-chip
            small
            label
            outlined
            class="primaryText--text mx-1 smallText"
            v-on="on"
          >
            <v-icon x-small left>category</v-icon> Date Type: {{ formatDateType(params.dateType)
            }}<v-icon small right>expand_more</v-icon>
          </v-chip>
        </template>
        <v-list style="overflow: hidden" dense>
          <v-list-item
            v-for="(mileType, index) in milestoneTypes"
            :key="index"
            @click="changeDateType(mileType.value)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ mileType.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-chip
            small
            label
            outlined
            class="primaryText--text mx-1 smallText"
            v-on="on"
          >
            <v-icon x-small left>event</v-icon>
            {{
              quickSelectDates.find((x) => x.value == params.selectedDate)
                ? quickSelectDates.find((x) => x.value == params.selectedDate)
                    .text
                : "Select Date"
            }}<v-icon small right>expand_more</v-icon>
          </v-chip>
        </template>
        <v-list style="overflow: hidden" dense>
          <v-list-item
            v-for="(date, index) in quickSelectDates"
            :key="index"
            @click="selectDate(date.value)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ date.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        v-if="params.selectedDate == 'select_dates'"
        ref="menu"
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            color="primary"
            class="mx-1"
            outlined
            label
            v-bind="attrs"
            v-on="on"
            small
            ><v-icon x-small left>date_range</v-icon>{{ dateText }}
            <v-icon small right>expand_more</v-icon></v-chip
          >
        </template>
        <v-date-picker
          v-model="params.dates"
          range
          color="primary"
          show-week
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary"> Cancel </v-btn>
          <v-btn text color="primary" @click="setDateFilter"> OK </v-btn>
        </v-date-picker>
      </v-menu>

      <v-spacer></v-spacer>
      <v-chip class="mr-1 searchChipWrap" style="max-width:550px">
        <v-icon small color="grey" class="ml-1">search</v-icon>
        <v-text-field
          placeholder="Search"
          class="mb-1 searchInput"
          hide-details
          rounded
          clearable
          dense
          style="margin-top: 2px"
          v-model="search"
        ></v-text-field>

        <v-tooltip top v-if="filterActive">
          <template v-slot:activator="{ on }">
            <v-btn
              rounded
              right
              small
              icon
              color="redPop"
              @click="resetFilters"
              v-on="on"
            >
              <v-icon>filter_alt_off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Clear Filters</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              right
              small
              color="blue"
              @click="getContainers"
              v-on="on"
            >
              <v-icon>autorenew</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Refresh Containers</span>
        </v-tooltip>
      </v-chip>
    </v-toolbar>
    <ag-grid-vue
      groupDisplayType="multipleColumns"
      :animateRows="false"
      :rowGroupPanelShow="'always'"
      :autoSizeStrategy="{
        type: 'fitCellContents',
        defaultMinWidth: 150,
        columnLimits: [],
      }"
      style="width: 100%; height: 95%"
      :enableRangeSelection="true"
      :allowContextMenuWithControlKey="true"
      :columnDefs="columns"
      :rowData="containers"
      :getContextMenuItems="getContextMenuItems"
      :suppressAggFuncInHeader="true"
      :defaultColDef="defaultColDef.def"
      :sideBar="sidebar"
      :statusBar="statusBar"
      :initialState="initialState"
      :getRowId="getRowId"
      @state-updated="onStateUpdated"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import ActionPanel from "./ContainerComponents/ActionPanel.vue";
import { AgGridVue } from "ag-grid-vue";
import Boolean from "../GridComponents/Boolean.vue";
import BookingModal from "../Bookings/BookingModal.vue";
import BookingState from "./BookingComponents/BookingState.vue";
import ContainerStatus from "../GridComponents/ContainerStatus.vue";
import BookingType from "./BookingComponents/BookingType.vue";
import FileStatus from "./ContainerComponents/FileStatus.vue";
import dateFormat from "dateformat";
import * as moment from "moment";
import MovementType from "./BookingComponents/MovementType.vue";
import OrganisationName from "../GridComponents/Organisation.vue";
import PortCell from "../GridComponents/Port.vue";
import ContainerStockStatus from "../GridComponents/ContainerStockStatus.vue";
import TableHeader from "../GridComponents/TableHeader.vue";
import TemporaryProducts from "../GridComponents/TemporaryProducts.vue";
import TransportStatus from "../GridComponents/TransportStatus.vue";
import UserCell from "../GridComponents/User.vue";
export default {
  components: {
    ActionPanel,
    AgGridVue,
    Boolean,
    BookingModal,
    BookingState,
    BookingType,
    ContainerStatus,
    ContainerStockStatus,
    FileStatus,
    MovementType,
    OrganisationName,
    PortCell,
    agColumnHeader: TableHeader,
    TemporaryProducts,
    TransportStatus,
    UserCell,
  },
  data: () => ({
    columns: [],
    containers: null,
    containerQuery: null,
    containerTimeout: null,
    dateKey: 200,
    dateMenu: false,
    defaultColDef: {
      def: {
        sortable: true,
        resizable: true,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
        flex: 1,
        minWidth: 100,
        cellClass: "d-flex align-center",
        cellStyle: { textAlign: "center", fontSize: "11px" },
      },
    },
    filterActive: false,
    filterSetting: {},
    gridApi: null,
    initialState: {},
    loading: false,
    milestoneTypes: [
      {
        name: "ETD",
        value: "ETD",
      },
      {
        name: "ETA",
        value: "ETA",
      },
      {
        name: "Loading Date",
        value: "LOADING_DATE",
      },
    ],
    params: {
      dateType: "ETD",
    },
    quickSelectDates: [
      {
        text: "Specify Dates",
        type: "current",
        value: "select_dates",
        difference: 0,
      },
      {
        text: 'Today',
        type: 'current',
        value: 'today',
        difference: 0
      },
      {
        text: 'Current 3 Days',
        type: 'current',
        value: 'current_3day',
        difference: 0
      },
      {
        text: "This week",
        type: "current",
        value: "current_week",
        difference: 0,
      },
      {
        text: "Next 2 weeks",
        type: "future",
        value: "2_week",
        difference: 0,
      },
    ],
    search: null,
    searchTimeout: null,
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  computed: {
    dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return "Week: " + moment(this.params.dates[0]).isoWeek();
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
  },
  created() {
    this.columns = [
      {
        headerName: "Booking Status",
        field: "state",
        cellRenderer: "BookingState",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Container Status",
        field: "formattedStatus",
        cellRenderer: "ContainerStatus",
        cellStyle: { textAlign: "center" },
      },
      {
        headerName: "Transport Status",
        field: "transportStatus",
        cellRenderer: 'TransportStatus',
        cellStyle: { textAlign: "center"},
      },
      {
        headerName: "Stock Status",
        field: "stockStatus",
        cellRenderer: "ContainerStockStatus"
      },
      {
        headerName: "User",
        field: "userFullname",
        cellRenderer: "UserCell",
        cellRendererParams: (p) => {
          return p.data && p.data.user
            ? {
                name: p.data.user.firstname,
                surname: p.data.user.surname,
                avatar: p.data.user.avatar,
              }
            : null;
        },
      },
      {
        headerName: "Order No.",
        field: "orderNo",
      },
      {
        field: "ctoNo",
        headerName: "CTO No.",
      },
      {
        headerName: "Container No.",
        field: "containerNo",
      },
      {
        field: "shipmentFile.fileNumber",
        headerName: "File",
        cellRenderer: "FileStatus",
        cellRendererParams: (p) => {
          return p.data && p.data.shipmentFile
            ? {
                status: p.data.shipmentFile.status,
                fileNumber: p.data.shipmentFile.fileNumber,
              }
            : p.value;
        },
      },
      {
        field: "customerContainerRef",
        headerName: "Customer Ref.",
      },
     
      {
        headerName: "Seal No.",
        field: "sealNo",
      },
      {
        headerName: "Type",
        field: "containerTypeCode",
      },
      {
        headerName: "Customer",
        field: "customerName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.customer
            ? {
                organisation: p.data.customer,
                clientStatus: p.data.clientStatus,
              }
            : null;
        },
      },
      {
        headerName: "Shipper",
        field: "shipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                organisation: p.data.shipper,
              }
            : null;
        },
      },
      {
        headerName: "Consignee",
        field: "consigneeName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                organisation: p.data.consignee,
              }
            : null;
        },
      },
      {
        headerName: "Carrier",
        field: "shippingLineName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shippingLine
            ? {
                organisation: p.data.shippingLine,
              }
            : null;
        },
      },
      {
        headerName: "Vessel Operator",
        field: "vesselOperatorName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.vesselOperator
            ? {
                organisation: p.data.vesselOperator,
              }
            : null;
        },
      },

      {
        headerName: "Transport Coordinator",
        field: "transportCoordinatorName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.transportCoordinator
            ? {
                organisation: p.data.transportCoordinator,
              }
            : null;
        },
      },
      {
        headerName: "Vessel/Voyage",
        field: "vesselVoyage",
      },
      {
        headerName: "POL",
        field: "portOfLoadValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "POD",
        field: "portOfDischargeValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfDischargeValue : null,
            name: p.data ? p.data.portOfDischargeCity : null,
          };
        },
      },
      
      {
        headerName: "Destination",
        field: "finalDestinationValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.finalDestinationValue : null,
            name: p.data ? p.data.finalDestinationCity : null,
          };
        },
      },
      {
        headerName: "Method",
        field: "verificationMethod",
      },
      {
        headerName: "Carrier Ref",
        field: "carrierReferenceNumber",
      },
      {
        headerName: "Features",
        field: "temporaryProducts",
        filter: false,
        cellRenderer: "TemporaryProducts",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Packing List",
        field: "packingListStatus",
        cellRenderer: "Boolean",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Loadout Instr.",
        field: "loadoutStatus",
        cellRenderer: "Boolean",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Dispatch",
        field: "dispatchStatus",
        cellRenderer: "Boolean",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "ETD",
        field: "etd"
      },
      {
        headerName: "ETA",
        field: "eta"
      },
      {
        headerName: "Products",
        field: "products",
      },
      {
        headerName: 'Milestones',
        children: [
        { headerName: 'Empty Depot', field: 'milestones.EMPTY_PICKUP.description',  valueFormatter: (p)=>{
              return p.data && p.data.milestones && p.data.milestones.EMPTY_PICKUP ? p.data.milestones.EMPTY_PICKUP.description : null
            } },
            { headerName: 'LP-1', field: 'milestones.LOADING_POINT_1.description', valueFormatter: (p)=>{
              return p.data && p.data.milestones && p.data.milestones.LOADING_POINT_1 ? p.data.milestones.LOADING_POINT_1.description : null
            } },
            { headerName: 'LP-2', field: 'milestones.LOADING_POINT_2.description', valueFormatter: (p)=>{
              return p.data && p.data.milestones && p.data.milestones.LOADING_POINT_2 ? p.data.milestones.LOADING_POINT_2.description : null
            } },
            { headerName: 'Loading Date', field: 'milestones.LOADING_POINT_1.date', valueFormatter: (p)=>{
              return p.data && p.data.milestones && p.data.milestones.LOADING_POINT_1 ? this.$Format.formatDate(p.data.milestones.LOADING_POINT_1.date).isoFormat : null
            } },
            { headerName: 'Loading Time', field: 'milestones.LOADING_POINT_1.time', valueFormatter: (p)=>{
              return p.data && p.data.milestones && p.data.milestones.LOADING_POINT_1 ? p.data.milestones.LOADING_POINT_1.time : null
            } },
            { headerName: 'Plug-in Depot', field: 'milestones.PLUG_IN.description', valueFormatter: (p)=>{
              return p.data && p.data.milestones && p.data.milestones.PLUG_IN ? p.data.milestones.PLUG_IN.description : null
            } },
            { headerName: 'Drop Off', field: 'milestones.FULL_DROP_OFF.description', valueFormatter: (p)=>{
              return p.data && p.data.milestones && p.data.milestones.FULL_DROP_OFF ? p.data.milestones.FULL_DROP_OFF.description : null
            } },
        ]
    },
    ]
    if (
      localStorage["containerSearchParams_" + this.$store.state.currentOrg.id]
    ) {
      this.params = JSON.parse(
        localStorage["containerSearchParams_" + this.$store.state.currentOrg.id]
      );
      if (!this.params) {
        this.params = {};
      }
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
      if (
        this.params.selectedDate &&
        this.params.selectedDate != "select_dates"
      ) {
        this.selectDate(this.params.selectedDate);
      }
    } else {
      this.params = {
        dateType: "ETD",
        selectedDate: "current_3day",
        dates: [],
      };
      this.selectDate("current_3day");
    }

    if (
      localStorage.getItem(
        "containersTableState_" + this.$store.state.currentOrg.id
      )
    ) {
      let state = JSON.parse(
        localStorage.getItem(
          "containersTableState_" + this.$store.state.currentOrg.id
        )
      );
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }

    this.sidebar = {
      toolPanels: [
        {
          id: "actions",
          labelDefault: "Actions",
          labelKey: "actions",
          iconKey: "chart",
          toolPanel: "ActionPanel",
          minWidth: 300,
          maxWidth: 500,
          width: 300,
          toolPanelParams: {
            setTeamFilter: (teams, allTeams) => {
              this.selectedTeams = teams;
              this.params.allTeams = allTeams;
              this.getContainers();
            },
            resetTable: this.resetTable,
            exportExcel: () => {
              this.gridApi.exportDataAsExcel();
            },
            exportCSV: () => {
              this.gridApi.exportDataAsCsv();
            },
          },
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
      defaultToolPanel: "ActionPanel",
    };
  },
  methods: {
    changeDateType(name) {
      if (name == "ETD") {
        this.params.dateType = "ETD";
      } else if (name == "ETA") {
        this.params.dateType = "ETA";
      } else if (name == "LOADING_DATE") {
        this.params.dateType = "LOADING DATE";
      }
      this.dateKey++;
      this.getContainers();
    },
    formatDateType(type){
        let find = this.milestoneTypes.find(x => x.value == type);
        return find ? find.name : type;
    },
    async getContainers() {
      if (this.containerTimeout) {
        clearTimeout(this.containerTimeout);
      }
      if (this.loading && this.containerQuery) {
        this.containerQuery.abort();
      }
      this.loading = true;
      // if (this.gridApi) {
      //   // this.gridApi.setRowData(null);
      //   this.gridApi.showLoadingOverlay();
      // }
      let params = { ...this.params };
      params.teams = this.selectedTeams;
      if(this.params.allTeams) {
        delete params.teams
      }
      if (this.params.dates.length == 1) {
        params.dates = [
          moment(this.params.dates[0])
            .startOf("isoWeek")
            .toISOString()
            .substring(0, 10),
          moment(this.params.dates[0])
            .endOf("isoWeek")
            .toISOString()
            .substring(0, 10),
        ];
      }
      this.selectedBookings = [];
      this.containerTimeout = setTimeout(async () => {
        this.containerQuery = new AbortController();
        const signal = this.containerQuery.signal;
        let result = await this.$API.getBookingContainerOverviewUpdated({
          params: params,
          signal,
        });
        this.containers = result;
        this.gridApi.setRowData(this.containers);
        this.loading = false;
      }, 500);
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
        {
            name: "View Booking",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
              console.log(params.node.data)
                this.viewBooking(params.node.data.booking);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    postProcessPopup(params) {
      if (params.type !== "columnMenu") {
        return;
      }
      const ePopup = params.ePopup;
      ePopup.style.top = "65px";
      ePopup.style.left =
        params.column.actualWidth / 2 + params.mouseEvent.left + "px";
    },
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    getRowId(params) {
      return params.data.id;
    },
    async load() {},
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      this.getContainers();
    },
    onStateUpdated(state) {
      localStorage.setItem(
        "containersTableState_" + this.$store.state.currentOrg.id,
        JSON.stringify(state.state)
      );
    },
    async resetFilters() {
      this.gridApi.setFilterModel({});
    },
    resetTable() {
      this.gridApi.setFilterModel(null);
      this.gridApi.setRowGroupColumns([]);
      this.gridApi.applyColumnState({ state: this.columns });
      this.gridApi.refreshCells({ force: true });
    },
    selectDate(value) {
      this.params.selectedDate = value;
      this.setDateRange(value);
      localStorage.setItem(
        "containerSearchParams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
    },
    setDateFilter() {
      if (this.params.dates.length == 2) {
        if (this.params.dates[1] < this.params.dates[0]) {
          let dates = [this.params.dates[1], this.params.dates[0]];
          this.params.dates = dates;
        }
      }
      localStorage.setItem(
        "containerSearchParams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
      this.dateMenu = false;
      this.getContainers();
    },
    setDateRange(val) {
      switch (val) {
        case "current_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_month":
          this.params.dates = [
            new Date(new Date().setDate(1)).toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "past_day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 3))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "today":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "tomorrow":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 7))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "2_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 14))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "6_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 42))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.getContainers();
    },
    viewBooking(item) {
      const url = "/booking/" + item.systemReference;
      window.open(url, '_blank');
    }
  },
};
</script>