<template>
  <div style="height: 100%">
    <v-toolbar flat dense>
      <v-spacer></v-spacer>
      <v-chip class="mr-1 searchChipWrap">
        <v-icon small color="grey">search</v-icon>
        <v-text-field
          placeholder="Search"
          class="mb-1 searchInput"
          hide-details
          rounded
          clearable
          dense
          style="margin-top: 2px; width: 300px"
          v-model="search"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip top v-if="filterActive">
          <template v-slot:activator="{ on }">
            <v-btn
              rounded
              right
              small
              icon
              color="redPop"
              @click="resetFilters"
              v-on="on"
            >
              <v-icon>filter_alt_off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Clear Filters</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon right small color="blue" @click="getOrders" v-on="on">
              <v-icon>autorenew</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Refresh Booking Requests</span>
        </v-tooltip>
      </v-chip>
    </v-toolbar>
    <ag-grid-vue
      groupDisplayType="multipleColumns"
      :animateRows="true"
      :rowGroupPanelShow="'always'"
      :autoSizeStrategy="{
        type: 'fitGridWidth',
        defaultMinWidth: 150,
        columnLimits: [{ colId: 'movementType', minWidth: 150 }],
      }"
      style="width: 100%; height: 95%"
      :enableRangeSelection="true"
      :allowContextMenuWithControlKey="true"
      :columnDefs="columns"
      :rowData="orders.data"
      :suppressAggFuncInHeader="true"
      :defaultColDef="defaultColDef"
      :sideBar="true"
      :statusBar="statusBar"
      :initialState="initialState"
      :getRowId="getRowId"
      :getContextMenuItems="getContextMenuItems"
      @state-updated="onStateUpdated"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>

    <v-dialog
      v-model="previewModal"
      width="1500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <PreviewBookingRequest
        :bookingRequest="bookingRequestItem"
        :containers="containers"
        :containerTypes="containerTypes"
        @close="previewModal = false"
        @refresh="getOrders"
      />
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import BookingState from "./BookingComponents/BookingState.vue";
import BookingType from "./BookingComponents/BookingType.vue";
import dateFormat from "dateformat";
import * as moment from "moment";
import MovementType from "./BookingComponents/MovementType.vue";
import UserCell from "../GridComponents/User.vue";
import OrganisationName from "@/components/GridComponents/Organisation.vue";
import PortCell from "@/components/GridComponents/Port.vue";
import TableHeader from "@/components/GridComponents/TableHeader.vue";
import PreviewBookingRequest from "../Bookings/Dialogs/PreviewBookingRequest.vue";

export default {
  components: {
    AgGridVue,
    BookingState,
    BookingType,
    MovementType,
    OrganisationName,
    PortCell,
    PreviewBookingRequest,
    UserCell,
    agColumnHeader: TableHeader,
  },
  data: () => ({
    bookingRequestItem: {},
    columns: [],
    containers: [],
    containerTypes: [],
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: true,
      enablePivot: true,
      flex: 1,
      minWidth: 100,
      cellClass: "d-flex align-center",
      cellStyle: { textAlign: "center", fontSize: "11px" },
    },
    filterActive: false,
    filterSetting: {},
    gridApi: null,
    initialState: {},
    loading: false,
    order: {},
    orderModal: false,
    orders: {
      data: [],
      total: 0,
    },
    organisations: {
      total: 0,
      data: null,
    },
    orderQuery: undefined,
    orderStatus: [
      { name: "Planned", icon: "schedule", color: "#F57C00" },
      { name: "Open", icon: "pending", color: "#2196F3" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
    ],
    orderTimeout: undefined,
    previewModal: false,
    search: null,
    searchTimeout: undefined,
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  async mounted() {
    this.columns = [
      //   {
      //     headerName: "Action",
      //     field: "actions",
      //   },
      {
        headerName: "Customer",
        field: "customerName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.customer
            ? {
                organisation: p.data.customer,
                clientStatus: p.data.clientStatus,
              }
            : null;
        },
      },
      {
        headerName: "Order No",
        field: "orderNo",
      },
      {
        headerName: "Week",
        field: "sailingWeek",
      },
      {
        headerName: "Order Status",
        field: "state",
        cellRenderer: "BookingState",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Shipper Ref. No",
        field: "shipperReferenceNo",
      },
      {
        headerName: "Movement Type",
        field: "movementType",
        cellRenderer: "MovementType",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Type",
        field: "type",
        cellRenderer: "BookingType",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },

      {
        headerName: "User",
        field: "userFullName",
        cellRenderer: "UserCell",
        valueGetter: (p) => {
          return p.data && p.data.orderUser
            ? p.data.orderUser.firstname + " " + p.data.orderUser.surname
            : null;
        },
        cellRendererParams: (p) => {
          return p.data && p.data.orderUser
            ? {
                name: p.data.orderUser.firstname,
                surname: p.data.orderUser.surname,
                avatar: p.data.orderUser.avatar,
              }
            : null;
        },
      },

      {
        headerName: "Vessel",
        field: "vesselVoyage",
      },
      {
        headerName: "Regime Code",
        field: "regimeCode",
      },

      {
        headerName: "Shipper",
        field: "shipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                organisation: p.data.shipper,
              }
            : null;
        },
      },
      {
        headerName: "On Behalf Shipper",
        field: "onBehalfShipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.onBehalfShipper
            ? {
                organisation: p.data.onBehalfShipper,
              }
            : null;
        },
      },
      {
        headerName: "Consignee",
        field: "consigneeName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                organisation: p.data.consignee,
              }
            : null;
        },
      },
      {
        headerName: "Carrier",
        field: "shippingLineName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shippingLine
            ? {
                organisation: p.data.shippingLine,
              }
            : null;
        },
      },

      {
        headerName: "POL",
        field: "portOfLoadValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "ETD",
        field: "etd",
        valueFormatter: (params) => {
          if (params.value) {
            let date = new Date(params.value);
            if (date.getHours() === 0 && date.getMinutes() === 0) {
              return this.formatDate(params.value);
            } else {
              return this.formatDateTime(params.value);
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "POD",
        field: "portOfDischargeValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfDischargeValue : null,
            name: p.data ? p.data.portOfDischargeCity : null,
          };
        },
      },
      {
        headerName: "Dest.",
        field: "finalDestinationValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.finalDestinationValue : null,
            name: p.data ? p.data.finalDestinationCity : null,
          };
        },
      },
      {
        headerName: "ETA",
        field: "eta",
        valueFormatter: (params) => {
          if (params.value) {
            let date = new Date(params.value);
            if (date.getHours() === 0 && date.getMinutes() === 0) {
              return this.formatDate(params.value);
            } else {
              return this.formatDateTime(params.value);
            }
          } else {
            return "";
          }
        },
      },

      {
        headerName: "Consignee Ref. No",
        field: "consigneeReferenceNo",
      },
    ];
  },
  async created() {
    if (
      localStorage.getItem(
        "bookingRequestTableState_" + this.$store.state.currentOrg.id
      )
    ) {
      let state = JSON.parse(
        localStorage.getItem(
          "bookingRequestTableState_" + this.$store.state.currentOrg.id
        )
      );
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }
    this.containerTypes = await this.$API.listContainerTypes();
  },
  methods: {
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "View Request",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
                this.previewRequest(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    getRowId(params) {
      return params.data.id;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      this.getOrders();
    },
    onStateUpdated(state) {
      localStorage.setItem(
        "bookingRequestTableState_" + this.$store.state.currentOrg.id,
        JSON.stringify(state.state)
      );
    },
    postProcessPopup(params) {
      if (params.type !== "columnMenu") {
        return;
      }
      const ePopup = params.ePopup;
      ePopup.style.top = "65px";
      ePopup.style.left =
        params.column.actualWidth / 2 + params.mouseEvent.left + "px";
    },
    async resetFilters() {
      this.gridApi.setFilterModel({});
    },
    async getOrders() {
      if (this.orderTimeout) {
        clearTimeout(this.orderTimeout);
      }
      if (this.loading && this.orderQuery) {
        this.orderQuery.abort();
      }
      this.loading = true;
      let params = {};
      params.recordType = "ORDER";
      this.orderTimeout = setTimeout(async () => {
        this.orderQuery = new AbortController();
        const signal = this.orderQuery.signal;
        this.orders = await this.$API.getBookingRequests({
          params: params,
          signal,
        });
        this.loading = false;
      }, 500);
    },
    async previewRequest(item) {
      this.bookingRequestItem = await this.$API.getOrderDetail(
        item.systemReference
      );
      this.containers = await this.$API.getOrderContainersBasic(item.id);
      this.previewModal = true;
    },
  },
};
</script>