<template>
  <div v-if="item && item.length > 0">
    <v-btn
      icon
      outlined
      x-small
      :color="product.color"
      class="mx-1"
      v-for="(product, index) in mapTemporaryProducts(item)"
      :key="index"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon x-small v-on="on">{{ product.icon }}</v-icon>
        </template>
        <span style="white-space: pre-line"
          >{{ product.product.replace(/_/g, " ") + " " + "Required" }}
        </span>
      </v-tooltip>
    </v-btn>
  </div>
</template>
    
    <script>
export default {
  data: () => ({
    item: null,
    group: null,
    temporaryProducts:  [
      { icon: "bolt", color: "#D6D46D", name: "Genset" },
      { icon: "watch_later", color: "#BB2525", name: "Late Arrival" },
      { icon: "watch_later", color: "orange", name: "Early Arrival" },
      { icon: "router", color: "#18e00d", name: "TelemPlus" },
      { icon: "shopping_bag", color: "#F1DBBF", name: "Dunnage Bags" },
      { icon: "air", color: "#6A9C89", name: "Otflow" },
      { icon: "sanitizer", color: "#5b93c7", name: "Zoono" },
      { icon: "cleaning_services", color: "#BB2525", name: "Fumigation" },
      { icon: "power", color: "#D6D46D", name: "Void Plugs" },
      { icon: "ac_unit", color: "#13a7d4", name: "Blast Freezing" },
      { icon: "pivot_table_chart", color: "#508D69", name: "Latches" },
      { icon: "settings_input_component", color: "#EA906C", name: "Ratches" },
      {icon:'filter_alt',color:"#EE12CA",name:"Ethylene Filters"},
      { icon: "video_stable", color: "#5F6F52", name: 'Stabling bars' },
      { icon: "note", color: "#C5E898", name: "Kraft paper" },
      { icon: "emergency", color: "#FFBFBF", name: 'SteriInsurance' },
      { icon: "backpack", color: "#DCBFFF", name: 'Absorb Bags' },      // { icon: "monitor_heart", color: "#F1DBBF", name: "Port Monitoring" },
    ],
  }),
  mounted() {
    this.item = this.params.value;
    if (this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
    }
  },
  methods: {
    getTextValue(status) {
      if (status) {
        let statusText = this.temporaryProducts.find(
          (x) => x.text.toLowerCase() === status.toLowerCase()
        );
        return statusText ? statusText.text : status;
      } else {
        return status;
      }
    },
    mapTemporaryProducts(temporaryProduct) {
      if (temporaryProduct && Array.isArray(temporaryProduct)) {
        let devicesList = "Devices Required:\n";
        return temporaryProduct.reduce((acc, product) => {
          const existingProduct = acc.find(
            (accProduct) => accProduct.product === product.product
          );
          if (!existingProduct) {
            const view = this.temporaryProducts.find(
              (view) => view.name === product.product
            );
            if (view) {
              if (view.name == "TelemPlus") {
                devicesList +=
                  "• " + product.count + "X" + product.variation + "\n";
                // const existingDevice = acc.find(
                //   (accProduct) => accProduct.product === 'Devices Required'
                // );
                // if(!existingDevice){
                // acc.push({
                //   product: 'Devices Required',
                //   variation: product.variation,
                //   icon: view.icon,
                //   color: view.color,
                // })
                // }
              } else if (view.name == "Late Arrival") {
                acc.push({
                  product:
                    product.product +
                    " " +
                    "Required" +
                    " : " +
                    product.variation,
                  icon: view.icon,
                  color: view.color,
                });
              } else if (view.name == "Early Arrival") {
                acc.push({
                  product:
                    product.product +
                    " " +
                    "Required" +
                    " : " +
                    product.variation,
                  icon: view.icon,
                  color: view.color,
                });
              } else if (view.name === "SteriInsurance") {
                acc.push({
                  product: "Steri Insurance" + " " + "Required",
                  icon: view.icon,
                  color: view.color,
                });
              } else if (view.name === "SmartFresh") {
                acc.push({
                  product: "Smart Fresh" + " " + "Required",
                  icon: view.icon,
                  color: view.color,
                });
              } else if (view.name === "ContainerLiners") {
                acc.push({
                  product: "Container Liners" + " " + "Required",
                  icon: view.icon,
                  color: view.color,
                });
              } else {
                acc.push({
                  product: product.product + " " + "Required",
                  icon: view.icon,
                  color: view.color,
                });
              }
            }
          }

          if (product.product === "Monitoring Service" && product.variation) {
            acc.push({
              product: product.variation + " " + "Service",
              variation: product.monitoringService,
              icon: "desktop_windows",
              color: "#99B080",
            });
          }
          if (devicesList.length > 17) {
            const existingDevice = acc.find(
              (accProduct) => accProduct.variation == "None"
            );
            if (!existingDevice) {
              acc.push({
                product: devicesList,
                variation: "None",
                icon: "router",
                color: "green",
              });
            } else {
              acc.splice(acc.indexOf(existingDevice), 1);
              acc.push({
                product: devicesList,
                variation: "None",
                icon: "router",
                color: "green",
              });
            }
          }
          return acc;
        }, []);
      } else return [];
    },
    statusColor(status) {
      if (status) {
        let color = this.temporaryProducts.find(
          (x) => x.text.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else {
        return "grey";
      }
    },
  },
};
</script>
    
    <style>
</style>